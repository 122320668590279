<template>
  <div>
    <v-dialog v-model="downgradeDialog" persistent max-width="430">
      <v-card>
        <v-card-title class="headline">Downgrade</v-card-title>

        <v-card-text>
          <p>
            You are downgrading to the
            <strong class="text-capitalize">{{ planName }}</strong> plan, this
            may impact your active links, are you sure?
          </p>

          <!-- <iframe
            v-if="planName === 'free'"
            width="100%"
            height="320px"
            src="https://surveys.hotjar.com/93cfa597-e977-4be5-8772-b17c80cb7732"
            title="We need your help"
            frameborder="0"
          /> -->
          <div v-if="planName === 'free'">
            <v-divider></v-divider>
            <p class="mt-3 black--text">Could you please let us know why you want to cancel your subscription? </p>
            <v-checkbox
                v-model="churnAnswers"
                dense
                label="Missing features I need"
                value="Missing features I need"
                ></v-checkbox>
                <v-checkbox
                v-model="churnAnswers"
                dense
                label="Technical issues"
                value="Technical issues"
                ></v-checkbox> 
                <v-checkbox
                v-model="churnAnswers"
                dense
                label="Marketing campaign ended"
                value="Marketing campaign ended"
                ></v-checkbox>
                <v-checkbox
                dense
                v-model="churnAnswers"
                label="Too expensive"
                value="Too expensive"
                ></v-checkbox>
                <v-checkbox
                dense
                v-model="churnAnswers"
                label="Found an alternative service"
                value="Found an alternative service"
                ></v-checkbox>
                <v-checkbox
                dense
                label="Other"
                value="Other"
                ></v-checkbox>

                <v-textarea
                v-model="churnOpenQuestion"
                  solo
                  label="Feedback"
                ></v-textarea>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="downgradeDialog = false"
            >Cancel</v-btn
          >

          <v-btn color="red darken-1" text @click="downgradePlanFinal()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="430">
      <v-card>
        <v-card-title class="headline">Subscribe</v-card-title>

        <v-card-text>
          You are subscribing to
          <strong class="text-capitalize">{{ planName }}</strong> plan.
          <br />
          <p>
            After clicking "Confirm" a secure popup from our payment processor
            "Paddle.com" will open, you will be able to enter your payments
            details and optionally your VAT tax number.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="upgradePlanFinal()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- if same plan disable btn -->
    <v-btn
      :small="smallBtn"
      v-if="
        userPlan.plan.name == planName &&
        userPlan.user.paddle_cancellation_effective_date
      "
      depressed
      @click="upgradePlan()"
      color="success"
      >Renew</v-btn
    >
    <v-btn small v-else-if="userPlan.plan.name == planName" disabled text
      >Current Plan</v-btn
    >
    <!-- allow to upgrade -->
    <v-btn
      :small="smallBtn"
      v-else-if="canUpgrade"
      depressed
      color="success"
      @click="upgradePlan()"
      >{{ $t("upgrade") }}</v-btn
    >
    <!-- downgrade -->
    <v-btn
      :small="smallBtn"
      v-else
      outlined
      color="red"
      @click="downgradePlan()"
      :disabled="userPlan.user.paddle_cancellation_effective_date !== null"
    >
      {{ $t("downgrade") }}
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { movePlan } from "../api";

export default {
  props: ["planName", "smallBtn"],
  data() {
    return {
      dialog: false,
      downgradeDialog: false,
      churnAnswers: [],
      churnOpenQuestion: ""
    };
  },
  computed: {
    ...mapState(["userPlan", "plans"]),
    ...mapGetters(["getUserEmail"]),
    canUpgrade() {
      return this.$store.getters.canUpgrade(this.planName);
    },
    planId() {
      for (const thisPlan of this.plans) {
        if (thisPlan.name === this.planName) {
          return thisPlan.activePaddleId;
        }
      }
      return -1;
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async downgradePlan() {
      this.downgradeDialog = true;
    },
    async downgradePlanFinal() {
      this.downgradeDialog = false;
      this.$store.commit("setIsLoading", true);
      const res = await movePlan(this.planName, {
        churnAnswers: this.churnAnswers,
        churnOpenQuestion: this.churnOpenQuestion,
      });
      console.log("res", res.success);
      if (res.success && res.success === true) {
        await this.sleep(3000);
        window.alert("Plan downgraded successfully");
      } else {
        window.alert(
          "Error: failed to downgrade the plan, please try again later or contact support"
        );
      }
      await this.$store.dispatch("getPlans");
      await this.$store.dispatch("getUserPlan");

      this.$store.commit("setIsLoading", false);
      // TODO: add toast after response?
    },
    upgradePlan() {
      this.dialog = true;
    },
    async upgradePlanFinal() {
      this.$store.commit("setIsLoading", true);
      this.dialog = false;
      // TODO: handle dialog
      const paddleUuid = this.userPlan.user.paddle_passthrough_id;
      const productId = this.planId;
      // upgrade by api
      if (
        this.userPlan.user.paddle_active_subscription_id &&
        !this.userPlan.user.paddle_cancellation_effective_date
      ) {
        const res = await movePlan(this.planName, {});
        if (res.success && res.success === true) {
          this.$store.dispatch("getPlans");
          this.$store.dispatch("getUserPlan");
          window.alert("Plan upgraded successfully");
        } else {
          window.alert(
            "Error: Failed to upgrade plan, please try to cancel your current plan (downgrade to free plan) and try again"
          );
        }
        // TODO: add toast after response?
        this.$store.commit("setIsLoading", false);
      } else {
        // new user/canceled subscription
        console.log(this.$store.user);
        // first time
        const paddlePayload = {
          passthrough: paddleUuid,
          product: productId,
          email: this.getUserEmail,
          success: `${this.$store.state.config.APP_URL}/#/processing`,
          allowQuantity: false,
          marketingConsent: true,
          // user clicked close, disable loading that was initialized at the top of
          // this function
          closeCallback: () => {
            try {
              // window.location.reload();
              this.$store.commit("setIsLoading", false);
            } catch (err) {}
          },
        }
        if (window.tolt_referral) {
          paddlePayload.customData = {
            tolt_referral: window.tolt_referral,
          }
        }
        window.Paddle.Checkout.open(paddlePayload);
      }
    },
  },
};
</script>
