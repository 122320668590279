<template>
  <v-container class="fill-height bg-auth" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" class="text-center">
        <img :src="$store.state.config.LOGO_URL" class="logo-card" />
        <v-card class="elevation-12">
          <v-toolbar color="grey lighten-3" flat>
            <v-toolbar-title>{{ $t("login") }}</v-toolbar-title>
            <v-spacer />
            <v-btn id="signup-page-btn" to="/signup" outlined>{{
              $t("signup")
            }}</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-btn block depressed @click="loginViaGoogle">
              <v-icon left>mdi-google</v-icon>{{ $t("login") }} via Google
            </v-btn>
            <v-divider class="my-3" />
            <v-form>
              <v-text-field
                v-model="email"
                id="email"
                label="Email"
                name="login"
                prepend-icon="mdi-email"
                type="text"
              />

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              block
              large
              depressed
              @click="loginAccount"
              id="login-btn"
            >
              {{ $t("login") }}
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="text-center mt-2">
          <v-btn text small @click="resetPassword">{{
            $t("resetPassword")
          }}</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { login, loginViaGoogle, auth } from "../auth";

export default {
  data() {
    return {
      email: null,
      password: null,
    };
  },
  mounted() {
    this.$store.commit("setIsLoading", false);
  },
  methods: {
    /**
     * allow to send reset password email
     */
    async resetPassword() {
      const emailToReset = window.prompt(
        "Please enter your email address, you will receive a reset email",
        typeof this.email === "string" ? this.email : undefined
      );
      // if user cancel the prompt the result is empty
      if (emailToReset === null) {
        return;
      }
      try {
        const resetSent = await auth().sendPasswordResetEmail(emailToReset, {
          url: "https://app.1link.io/#/login",
        });
        window.alert("Reset email successfully sent, please check your inbox");
      } catch (err) {
        console.error(err);
        window.alert(
          "Failed to send reset email, make sure the email entered is correct and try again"
        );
      }
    },
    async loginViaGoogle() {
      this.$store.commit("setIsLoading", true);
      try {
        await loginViaGoogle();
        this.$router.push("/links");
      } catch (err) {
        console.error(err);
        if (err.code !== "auth/popup-closed-by-user") {
          window.alert(err.message);
        }
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    async loginAccount() {
      if (!this.email || !this.password) {
        window.alert(this.$t("emailPswWrong"));
        return;
      }
      this.$store.commit("setIsLoading", true);
      try {
        await login(this.email, this.password);

        this.$router.push("/links");
      } catch (err) {
        console.error(err);
        if (
          err.code === "auth/user-not-found" ||
          err.code === "auth/wrong-password"
        ) {
          window.alert(this.$t("emailPswWrong"));
        } else {
          window.alert(err.message);
        }
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

<style scoped>
.bg-auth {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)),
    url("../assets/bg-auth.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.logo-card {
  max-width: 200px;
  text-align: center;
  margin-bottom: 22px;
}
</style>
