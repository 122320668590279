import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import messages from "./locale";
import Clipboard from "v-clipboard";
import { auth } from "./auth";
import * as Sentry from "@sentry/vue";

import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(Clipboard);

Vue.prototype.$vueEventBus = new Vue();

const i18n = new VueI18n({
  locale: store.state.locale, // set locale
  messages, // set locale translations (must be `messages`)
  fallbackLocale: "en",
});

try {
Sentry.init({
  Vue,
  dsn: "https://0ceced49ff47a019b40f25dfb8fdb64a@o4508388225974272.ingest.de.sentry.io/4508388227350608",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        "api.1link.io",
        /^https:\/\/api\.1link\.io/,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
} catch (err) {
  console.log('sentry err', err);
}


let app = null;
// allow to load the user (and set it to vuex) before rendering vuejs
auth().onAuthStateChanged((user) => {
  // already rendered vuejs, return
  if (app !== null) {
    return;
  }

  if (user) {
    // is auth
    store.commit("setUser", user);
  }

  app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");

  // attach current vue instance to store so we can use it easily
  store.$app = app;
});
