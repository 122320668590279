<template>
  <v-container class="fill-height bg-auth" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" class="text-center">
        <img :src="$store.state.config.LOGO_URL" class="logo-card" />
        <v-card class="elevation-12">
          <v-toolbar color="grey lighten-3" flat>
            <v-toolbar-title>{{ $t("signup") }}</v-toolbar-title>
            <v-spacer />
            <v-btn id="login-page-btn" to="/login" outlined>{{
              $t("login")
            }}</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-btn block depressed @click="signupViaGoogle">
              <v-icon left>mdi-google</v-icon>{{ $t("signup") }} via Google
            </v-btn>
            <v-divider class="my-3" />
            <v-form>
              <v-text-field
                v-model="email"
                id="email"
                label="Email"
                name="login"
                prepend-icon="mdi-email"
                type="text"
              />

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
              />

              <v-text-field
                id="confirmPassword"
                :label="$t('confirmPassword')"
                name="confirmPassword"
                prepend-icon="mdi-lock"
                type="password"
                v-model="confirmPassword"
              />

              <v-checkbox v-model="privacy" id="privacy-checkbox">
                <template v-slot:label>
                  <div>
                    I agree and consent 1LINK.IO to process data according to
                    <a href="/#/privacy-policy" target="_blank" @click.stop
                      >Privacy Policy</a
                    >
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="tos" id="tos-checkbox">
                <template v-slot:label>
                  <div>
                    I agree to
                    <a href="/#/terms-of-service" target="_blank" @click.stop
                      >Terms Of Service</a
                    >
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              block
              large
              depressed
              @click="signupAccount"
              id="signup-btn"
              :disabled="!tos || !privacy"
            >
              {{ $t("signup") }}
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { signup, signupViaGoogle, sendEmailVerification, auth } from "../auth";
import { initAccount } from "../api";

export default {
  data() {
    return {
      privacy: false,
      tos: false,
      email: null,
      password: null,
      confirmPassword: null,
    };
  },
  mounted() {
    this.$store.commit("setIsLoading", false);
  },
  methods: {
    initAccount() {
    try {
      if (window.tolt_referral) {
        const initialUser = auth().currentUser;
        if (initialUser && initialUser.email) {
          window.tolt.signup(initialUser.email)
        }
      }
    } catch (err) {
      console.error(err);
    }

      return initAccount()
    },
    async signupViaGoogle() {
      this.$store.commit("setIsLoading", true);
      try {
        await signupViaGoogle();
        // await sendEmailVerification();
        await this.initAccount();

        const initialUser = auth().currentUser;
        this.$store.commit("setUser", initialUser);

        this.$router.push("/almost-done");
      } catch (err) {
        console.error(err);
        if (err.code !== "auth/popup-closed-by-user") {
          window.alert(err.message);
        }
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    async signupAccount() {
      if (
        !this.email ||
        !this.password ||
        this.password !== this.confirmPassword
      ) {
        window.alert(this.$t("emailPswWrong"));
        return;
      }
      this.$store.commit("setIsLoading", true);
      try {
        await signup(this.email, this.password);
        // await sendEmailVerification();
        await this.initAccount();

        const initialUser = auth().currentUser;
        this.$store.commit("setUser", initialUser);

        this.$router.push("/verify-email");
      } catch (err) {
        console.error(err);
        window.alert(err.message);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

<style scoped>
.bg-auth {
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)),
    url("../assets/bg-auth.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.logo-card {
  max-width: 200px;
  text-align: center;
  margin-bottom: 22px;
}
</style>
