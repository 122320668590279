<template>
  <div>
    <v-container fluid class="mt-6">
      <v-row dense>
        <v-col cols="3">
          <v-card outlined class="text-center" min-height="155">
            <v-card-title class="headline justify-center">Plan</v-card-title>

            <v-card-text>
              <div class="black--text text-h4 text-uppercase">
                {{ userPlanObject.label }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card outlined class="text-center">
            <v-card-title class="headline justify-center"
              >Clicks this month</v-card-title
            >

            <v-card-text>
              <div
                class="black--text text-h4"
                style="letter-spacing: 2px !important"
              >
                {{ monthlyClicksCounter }}
              </div>
              <div class="subtitle mt-3">
                Plan limit
                {{
                  userPlanObject.monthly_clicks === -1
                    ? "Unlimited"
                    : userPlanObject.monthly_clicks
                }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card outlined class="text-center">
            <v-card-title class="headline justify-center"
              >Links created</v-card-title
            >

            <v-card-text>
              <div
                class="black--text text-h4"
                style="letter-spacing: 2px !important"
              >
                {{ totalLinksCounter }}
              </div>
              <div class="subtitle mt-3">
                Plan limit
                {{
                  userPlanObject.max_links === -1
                    ? "Unlimited"
                    : userPlanObject.max_links
                }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card flat class="text-center">
            <v-card-text>
              <div>
                <v-btn
                  outlined
                  color="primary"
                  block
                  small
                  @click="openDialogForNewLink()"
                  ><v-icon left>mdi-plus</v-icon> Create new link</v-btn
                >
              </div>
              <div class="my-2">
                <v-btn
                  outlined
                  color="primary"
                  block
                  small
                  :href="userPlan.user.metabase_analytics_url"
                  target="_blank"
                  ><v-icon left>mdi-chart-bar</v-icon> Analytics overview</v-btn
                >
              </div>
              <div class="my-2">
                <v-btn outlined color="primary" block small to="/plan"
                  >Upgrade plan</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card flat>
      <link-dialog ref="linkDialog" />
      <v-card-title>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search links by short handle, note"
          single-line
          hide-details
          clearable
          @input="fetchEntriesDebounced"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="linkList"
        :loading="tableLoading"
        :server-items-length="totalLinksCount"
        :options.sync="tableOptions"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $tc("link", 2) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click.stop="openDialogForNewLink"
              >New {{ $tc("link") }}</v-btn
            >
          </v-toolbar>
        </template>
        <!-- add chart https://dmtrbrl.github.io/vue-trend-chart/ -->
        <template v-slot:item.appInfo="{ item }">
          <span v-if="item.landing_page">{{ item.landing_page.title }}</span>
          <span v-else class="grey--text text--darken-1">Not available</span>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span class="grey--text text--darken-2">{{
            new Date(item.created_at).toLocaleString()
          }}</span>
        </template>
        <template v-slot:item.fullUrl="{ item }">{{ item.full_url }}</template>
        <template v-slot:item.note="{ item }">
          <div class="text-truncate" style="max-width: 200px; display: block">
            {{ item.note }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="openLinkDialogForShare(item.full_url, item.id)"
                v-on="on"
              >
                <v-icon>mdi-share</v-icon>
              </v-btn>
            </template>
            <span>Share</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="editLink(item.id)" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :href="
                  userPlan.user.metabase_analytics_url.replace(
                    '#',
                    '?link_id=' + item.id + '#'
                  )
                "
                target="_blank"
                v-on="on"
              >
                <v-icon>mdi-chart-areaspline-variant</v-icon>
              </v-btn>
            </template>
            <span>See detailed analytics for this link</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <!--<v-btn color="primary" @click="initialize" depressed small>Reload</v-btn>
          -->
          <span
            >No links found, create your first link by clicking on "New link"
            button</span
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { newLink as newLinkRequest, listLinks, getLinkByLinkId, initAccount } from "../api";
import { mapState, mapGetters } from "vuex";
import LinkDialog from "../components/LinkDialog.vue";

export default {
  components: {
    LinkDialog,
  },
  data: () => ({
    linkIdToLoad: null,
    tableOptions: {
      itemsPerPage: 10,
      page: 1,
    },
    tableLoading: true,
    linkListRes: {},
    linkList: [],
    totalLinksCount: 0,
    search: "",
    headers: [
      {
        text: "App Info",
        align: "start",
        sortable: false,
        value: "appInfo",
      },
      { text: "URL", value: "fullUrl", sortable: false },
      {
        text: "Note",
        value: "note",
        sortable: false,
        align: "center",
        width: "255px",
      },
      {
        text: "Clicks (this month)",
        value: "clicks_count",
        align: "center",
        sortable: false,
      },
      {
        text: "Created at",
        value: "created_at",
        align: "center",
        sortable: false,
      },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
  }),
  watch: {
    tableOptions: {
      handler() {
        if (!this.isLoading) {
          this.initialize();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.$store.dispatch("getUserPlan");
    await this.initialize();
    this.$store.commit("setIsLoading", false);

    this.$store.dispatch("getAvailableDomains");
    this.$vueEventBus.$on("linkListFetch", this.initialize);
  },
  computed: {
    ...mapState(["userPlan", "isLoading"]),
    ...mapGetters([
      "totalLinksCounter",
      "userPlanObject",
      "monthlyClicksCounter",
    ]),
  },
  methods: {
    async editLink(id) {
      // get link
      // set parameters
      if (typeof id !== "number") {
        window.alert("Failed to load link");
        return;
      }
      const linkDialog = this.$refs.linkDialog;
      linkDialog.init(id);
      this.openLinkDialog();
    },
    openLinkDialog() {
      const linkDialog = this.$refs.linkDialog;
      linkDialog.open();
    },
    openLinkDialogForShare(fullUrl, linkId) {
      const linkDialog = this.$refs.linkDialog;
      linkDialog.openShareDialog(fullUrl, linkId);
    },
    openDialogForNewLink() {
      const linkDialog = this.$refs.linkDialog;
      linkDialog.init();

      this.openLinkDialog();
    },
    fetchEntriesDebounced() {
      // prevent dialog open bug
      this.dialog = false;

      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        console.log("debounce done");
        this.initialize();
      }, 500);
    },
    async initialize() {
      try {
        this.tableLoading = true;
        console.log("initialize called");
        this.linkListRes = await listLinks({
          keyword: this.search,
          take: this.tableOptions.itemsPerPage,
          skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
        });
        this.linkList = this.linkListRes.result;
        console.log("search:", this.search);
        console.log("userplan", this.userPlan.user);
        console.log("links list count", this.linkListRes.count);

        this.totalLinksCount =
          this.search && this.search.length > 0
            ? this.linkListRes.count
            : this.userPlan.user.links_count || 0;

        console.log("links found", this.totalLinksCount);

        this.tableLoading = false;
      } catch (err) {
        console.error(err);
        let msg = "Internal server error, please try again";
        if (err && "response" in err && "data" in err.response) {
          if (err.response.data.message == "Internal server error, please try again") {
            await initAccount();
            window.location.reload();
          }
          msg = err.response.data.message;
        }
        window.alert(msg);
      }
    },
  },
};
</script>
